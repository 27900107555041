import React, { useState, useEffect, useRef } from "react";

import Swal from "sweetalert2";

import { ACRE_RANGES, RANGE_PAIRS } from "constants";
import {
    fetch,
    parseErrorResponse,
    formatNumber,
    formatMoneyRounded,
    format_metric_value,
} from "functions";

import { ScrollSpy, scrollToSection } from "../ui/scrollspy";
import { TitleBarButton } from "../ui/titlebar_button";
import { NavButton } from "../ui/nav_button";

const SECTION_PARCEL = "parcel";
const SECTION_OWNERSHIP = "ownership";
const SECTION_STRUCTURE = "structure";
const SECTION_OTHER = "other";
const SECTION_METRICS = "metrics";

// Sentinel value to indicate error state
const ERROR = "error";

const TARGET_RANGES = [
    "1 acre-2 acre",
    "2 acre-5 acre",
    "5 acre-10 acre",
    "10 acre-20 acre",
    "20 acre-40 acre",
    "40 acre-100 acre",
];

const TARGET_RANGE_PAIRS = RANGE_PAIRS.filter(([_, __, key]) =>
    TARGET_RANGES.includes(key),
);

const TARGET_METRICS = [
    "Active",
    "Pending",
    "Sold: 1mo",
    "Sold: 3mo",
    "Sold: 6mo",
    "Sold: 1yr",
    "1yr STR",
];

export default function ParcelDetail({ parcelID, onClose }) {
    const containerRef = useRef();
    const [activeSection, setActiveSection] = useState(SECTION_PARCEL);
    const [parcel, setParcel] = useState();
    const [county, setCounty] = useState();

    useEffect(() => {
        const fetchParcel = async (id) => {
            try {
                const parcel = await fetch(`/api/property/parcels/${id}/`);
                setParcel(parcel);
                console.log("Parcel detail", parcel);
            } catch (xhr) {
                const errorMessage =
                    xhr.status === 404
                        ? `No data available for parcel ${id}`
                        : parseErrorResponse(xhr);
                Swal.fire({
                    title: "Error",
                    text: errorMessage,
                    icon: "error",
                    confirmButtonText: "OK",
                });
            }
        };

        fetchParcel(parcelID);
    }, [parcelID]);

    useEffect(() => {
        if (!parcel) {
            return;
        }

        const fetchCounty = async (fips) => {
            try {
                const county = await fetch(`/api/county_metrics/${fips}/`);
                console.log("County", county);
                setCounty(county);
            } catch (xhr) {
                setCounty(ERROR);
                console.error("Error loading county", xhr);
            }
        };

        fetchCounty(parcel.FIPS);
    }, [parcel]);

    return (
        <div id="parcel-detail" className="d-flex flex-column">
            <div id="parcel-detail-main" className="d-flex flex-fill">
                <div id="parcel-detail-nav" className="d-flex flex-column">
                    <NavButton
                        name="Overview"
                        icon="fa-map-marker"
                        selected={activeSection === SECTION_PARCEL}
                        onClick={() => scrollToSection(containerRef, SECTION_PARCEL)}
                    />
                    <NavButton
                        name="Ownership"
                        icon="fa-user-group"
                        selected={activeSection === SECTION_OWNERSHIP}
                        onClick={() => scrollToSection(containerRef, SECTION_OWNERSHIP)}
                    />
                    <NavButton
                        name="Structure"
                        icon="fa-house"
                        selected={activeSection === SECTION_STRUCTURE}
                        onClick={() => scrollToSection(containerRef, SECTION_STRUCTURE)}
                    />
                    <NavButton
                        name="Other"
                        icon="fa-plus"
                        selected={activeSection === SECTION_OTHER}
                        onClick={() => scrollToSection(containerRef, SECTION_OTHER)}
                    />
                    <NavButton
                        name="Metrics"
                        icon="fa-calculator"
                        selected={activeSection === SECTION_METRICS}
                        onClick={() => scrollToSection(containerRef, SECTION_METRICS)}
                    />
                </div>
                <div
                    id="parcel-detail-content"
                    className="d-flex flex-column flex-fill"
                >
                    <div id="parcel-detail-header" className="d-flex py-3">
                        <h4 className="flex-fill mb-0">Parcel Detail</h4>
                        <TitleBarButton icon="fa-ellipsis" />
                        <TitleBarButton icon="fa-circle-xmark" onClick={onClose} />
                    </div>
                    <ScrollSpy
                        containerRef={containerRef}
                        onActiveSectionChange={setActiveSection}
                    />
                    <div
                        ref={containerRef}
                        id="parcel-detail-body"
                        className="flex-fill pe-3"
                    >
                        {!parcel ? (
                            "Loading..."
                        ) : (
                            <ParcelDetailBody parcel={parcel} county={county} />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

function ParcelDetailBody({ parcel, county }) {
    const {
        APN,
        SitusLatitude,
        SitusLongitude,
        SitusFullStreetAddress,
        SitusCity,
        SitusState,
        SitusZIP4,
        SitusZIP5,
        AssdLandValue,
        AssdTotalValue,
        AssdYear,
        EffectiveYearBuilt,
        LandUseCode,
        LotSizeAcres,
        LotSizeSqFt,
        MailingCity,
        MailingFullStreetAddress,
        MailingState,
        MailingZIP4,
        MailingZIP5,
        OutOfCountyOwner,
        OutOfStateOwner,
        OutOfZipOwner,
        Owner1FirstName,
        Owner1LastName,
        Owner1MiddleName,
        OwnershipLength,
        SchoolDistrictName,
        SitusCensusTract,
        SumBuildingSqFt,
        SumBuildingsNbr,
        Zoning,
    } = parcel;

    const ownerItems = [
        {
            label: "Owner",
            value: (
                <span>
                    {Owner1FirstName} {Owner1MiddleName} {Owner1LastName}
                </span>
            ),
        },
        {
            label: "Mailing Address",
            value: (
                <span>
                    {MailingFullStreetAddress}, {MailingCity}, {MailingState}{" "}
                    {MailingZIP5}-{MailingZIP4}
                </span>
            ),
        },
        { label: "Out Of State", value: boolToStr(OutOfStateOwner) },
        { label: "Out Of County", value: boolToStr(OutOfCountyOwner) },
        { label: "Out Of Zip", value: boolToStr(OutOfZipOwner) },
        { label: "Ownership Length", value: formatNumber(OwnershipLength) },
    ];

    const structureItems = [
        {
            label: "GPS",
            value: (
                <span>
                    {SitusLatitude}, {SitusLongitude}
                </span>
            ),
        },
        { label: "Parcel #", value: APN },
        { label: "Lot Size Acres", value: formatNumber(LotSizeAcres / 1000) },
        { label: "Land Sqft", value: formatNumber(LotSizeSqFt) },
        { label: "Assessed Year", value: AssdYear },
        { label: "Property Value", value: formatMoneyRounded(AssdTotalValue) },
        { label: "Land Value", value: formatMoneyRounded(AssdLandValue) },
        { label: "Census Tract", value: SitusCensusTract },
        { label: "Zoning", value: Zoning },
        { label: "Land Use Code", value: LandUseCode },
        { label: "Building Sqft", value: formatNumber(SumBuildingSqFt) },
        { label: "Buildings Count", value: formatNumber(SumBuildingsNbr) },
        { label: "Year Built", value: EffectiveYearBuilt },
    ];

    const otherItems = [{ label: "School District", value: SchoolDistrictName }];

    return (
        <>
            <SectionHeader section={SECTION_PARCEL} first nohr>
                {SitusFullStreetAddress}, {SitusCity}, {SitusState} {SitusZIP5}-
                {SitusZIP4}
            </SectionHeader>
            <SectionHeader section={SECTION_OWNERSHIP}>Ownership</SectionHeader>
            <Table items={ownerItems} />
            <SectionHeader section={SECTION_STRUCTURE}>Structure</SectionHeader>
            <Table items={structureItems} />
            <SectionHeader section={SECTION_OTHER}>Other</SectionHeader>
            <Table items={otherItems} />
            <SectionHeader section={SECTION_METRICS}>Metrics</SectionHeader>
            <CountyMetrics parcel={parcel} county={county} />
        </>
    );
}

function CountyMetrics({ parcel, county }) {
    if (!county) {
        return <p>Loading...</p>;
    } else if (county === ERROR) {
        return <p>Error loading country metrics.</p>;
    }

    const noMetricExist = (
        <p>
            No relevant metrics exist for this acreage range in{" "}
            <strong>{county.name}</strong>.
        </p>
    );

    const lotSize = parcel.LotSizeAcres / 1000;
    const range = TARGET_RANGE_PAIRS.find(
        ([minAcre, maxAcre, _]) => lotSize >= minAcre && lotSize < maxAcre,
    );
    if (!range) {
        return noMetricExist;
    }

    const [_, __, acreageKey] = range;
    const metrics = (county.stats || []).find((stat) => stat.type === acreageKey);
    if (!metrics) {
        return noMetricExist;
    }

    return (
        <div>
            <h6>
                {county.name}
                <div className="text-sm text-muted text-normal">{metrics.type}</div>
            </h6>
            <Table
                items={TARGET_METRICS.map((key) => ({
                    label: key,
                    value: format_metric_value(key, metrics.data[key]),
                }))}
            />
        </div>
    );
}

function SectionHeader({ section, children, first, nohr }) {
    return (
        <>
            <h5 data-section={section} className={`mb-1 ${first ? "" : "mt-4"}`}>
                {children}
            </h5>
            {!nohr && <hr className="horizontal dark mt-0" />}
        </>
    );
}

function Table({ items }) {
    return (
        <table className="table table-sm">
            <tbody>
                {items.map(({ label, value }) => (
                    <tr key={label}>
                        <th>{label}:</th>
                        <td className="text-wrap">{value}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

function boolToStr(value) {
    return value ? "Yes" : "No";
}
