import { useEffect, useState } from "react";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";

import { fetch, post, parseErrorResponse } from "functions";

const TIMEOUT_INTERVAL = 1000;

interface ExportListModalProps {
    filename: string;
    dumpFilters: () => Record<string, any>;
    onClose: () => void;
}

interface ParcelExport {
    id: string;
    file: string;
    error: any;
}

export default function ExportListModal({
    filename,
    dumpFilters,
    onClose,
}: ExportListModalProps) {
    const [parcelExport, setParcelExport] = useState<ParcelExport>();
    const [error, setError] = useState("");

    useEffect(() => {
        let timeoutID: any;

        const createExport = async () => {
            const filters = dumpFilters();

            let parcelExport: ParcelExport;
            try {
                parcelExport = await post("/api/property/parcel_exports/", {
                    filename,
                    search_filters: filters,
                });
            } catch (xhr) {
                console.log(xhr);
                setError(`Error exporting parcels: ${parseErrorResponse(xhr)}`);
                return;
            }

            if (parcelExport.error) {
                setError(
                    `Error exporting parcels: ${JSON.stringify(parcelExport.error)}`,
                );
            } else if (parcelExport.file) {
                setParcelExport(parcelExport);
            } else {
                timeoutID = setTimeout(
                    () => refreshParcelExport(parcelExport.id),
                    TIMEOUT_INTERVAL,
                );
            }
        };

        const refreshParcelExport = async (id: string) => {
            let parcelExport: ParcelExport;

            try {
                parcelExport = await fetch(`/api/property/parcel_exports/${id}/`);
            } catch (xhr) {
                console.log(xhr);
                setError(`Error exporting parcels: ${parseErrorResponse(xhr)}`);
                return;
            }

            if (parcelExport.error) {
                setError(
                    `Error exporting parcels: ${JSON.stringify(parcelExport.error)}`,
                );
            } else if (parcelExport.file) {
                setParcelExport(parcelExport);
            } else {
                timeoutID = setTimeout(() => refreshParcelExport(id), TIMEOUT_INTERVAL);
            }
        };

        createExport();

        return () => {
            if (timeoutID) {
                clearTimeout(timeoutID);
            }
        };
    }, [filename, dumpFilters]);

    return (
        <Modal show size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Export Parcels
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="text-center">
                    {error ? (
                        <span className="text-danger">{error}</span>
                    ) : parcelExport ? (
                        <Button
                            as="a"
                            variant="primary"
                            size="lg"
                            className="mb-0"
                            href={parcelExport.file}
                            target="_blank"
                            rel="noreferrer"
                        >
                            Download
                        </Button>
                    ) : (
                        <>
                            Loading... <Spinner animation="border" size="sm" />
                        </>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
