import { useState, useCallback } from "react";

import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { post, parseErrorResponse } from "functions";

interface SaveListModalProps {
    initialTitle: string;
    dumpFilters: () => Record<string, any>;
    onClose: () => void;
}

interface ParcelList {
    id: string;
}

export default function SaveListModal({
    initialTitle,
    dumpFilters,
    onClose,
}: SaveListModalProps) {
    const [parcelList, setParcelList] = useState<ParcelList>();

    // Form UI state
    const [title, setTitle] = useState(initialTitle);
    const [error, setError] = useState("");

    const onSave = useCallback(async () => {
        try {
            const filters = dumpFilters();
            const parcelList = await post("/api/property/parcel_lists/", {
                title,
                search_filters: filters,
            });
            setParcelList(parcelList);
        } catch (xhr) {
            console.log(xhr);
            setError(`Error saving list: ${parseErrorResponse(xhr)}`);
        }
    }, [title, dumpFilters]);

    if (parcelList) {
        return <SaveListConfirmationModal onClose={onClose} />;
    }

    return (
        <Modal show size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Save List</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error && <Alert variant="danger">{error}</Alert>}
                <Form>
                    <Form.Group controlId="formTitle">
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={onSave}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

interface SaveListConfirmationModalProps {
    onClose: () => void;
}

function SaveListConfirmationModal({ onClose }: SaveListConfirmationModalProps) {
    return (
        <Modal show size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Save List</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className="text-center">Saved!</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={onClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
